import React, { useEffect, useRef, useState } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import {
  Login,
  Dashboard,
  NoticeListPage,
  NoticeDetailPage,
  Order,
  InhouseNotice,
  InhouseNoticeDetail,
  As,
  AsRegister,
  Settings,
  Part,
  PartRegister,
  Tds,
  TdsRegister,
  Consignment,
  Purchase,
  Consult,
  ConsultRegister,
  ConsultImsiList,
  Schedule,
  Account,
  PurchaseEdit,
  ConsignmentEdit,
  User,
  UserRegister,
  Check,
} from "../pages";
import { SWRConfig } from "swr";
import AlertPopup from "../component/popup/AlertPopup";
import useLoad from "../hooks/useLoad";
import swrConfig from "../swrConfig";
import withUser from "../hoc/withUser";
import WithAccessAuth from "../hoc/WithAccessAuth";
import Core from "../container/base/Core";
import moment from "moment";
import auth from "../utils/auth";
import { useRecoilState } from "recoil";
import { logoutSettingState } from "../recoil/atom/modal";

const App = () => {
  const [alertPopup, setAlertPopup] = useState(null);

  const failAlertPopup = ({ state, title, text }) => {
    setAlertPopup({
      state,
      title,
      text,
    });
  };

  useLoad();

  const interval = useRef(null);
  const [logoutTime, setLogoutTime] = useState(null);

  function setDateTime(item,type,unit){
    let setDate = "";
    if(item){
      let basicDate = String(item).indexOf("-") > 0 || String(item).indexOf(".") > 0 ? String(item).replace(/-\./g,"/") : item;
      let date = new Date(basicDate),
        yy,
        mm,
        dd,
        th,
        tm,
        ts;
  
      yy = date.getFullYear();
      mm = date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      dd = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
      th = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
      tm = date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
      ts = date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds();
      
      if(type == "yymmdd"){
        if(unit == "ymd"){
          setDate = yy + "년 " + mm + "월 " + dd + "일";
        }else if(unit == "hymd"){
          setDate = yy + "-" + mm + "-" + dd;
        }else if(unit == "not"){
          setDate = `${yy}${mm}${dd}`;
        }else{
          setDate = yy + "." + mm + "." + dd;
        }
      }else if(type == "yymmddhhtt"){
        if(unit == "ymd"){
          setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm;
        }else if(unit == "hymd"){
          setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm;
        }else{
          setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm;
        }
      }else if(type == "mmdd"){
        if(unit == "ymd"){
          setDate = mm + "월 " + dd + "일";
        }else if(unit == "hymd"){
          setDate = mm + "-" + dd;
        }else if(unit == "slash"){
          setDate = mm + "/" + dd;
        }else{
          setDate = mm + "." + dd;
        }
      }else if(type == "yymm"){
        if(unit == "ymd"){
          setDate = yy + "년 " + mm + "월";
        }else if(unit == "hymd"){
          setDate = yy + "-" + mm;
        }else if(unit == "slash"){
          setDate = yy + "/" + mm;
        }else{
          setDate = yy + "." + mm;
        }
      }else if(type == "day"){
        setDate = dd;
      }else if(type == "yymmddhhttss"){
        if(unit == "ymd"){
          setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm + ":" + ts;
        }else if(unit == "hymd"){
          setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm + ":" + ts;
        }else{
          setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
        }
      }else if(type == "hhttss"){
        setDate = th + ":" + tm + ":" + ts;
      }else if(type == "hhtt"){
        setDate = th + ":" + tm;
      }else{
        setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
      }
    }
    return setDate;
  }

  function logoutTimeSetting(){
    fetch(`${process.env.REACT_APP_API_END_POINT}/manager/setting/logout`, {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("token"),
          'Accept' : 'application/json'
      },
    }).then(function(response) {
        return response.json();
    }).then(function(fetchRd) {
        if (fetchRd.success == true) {
            setLogoutTime(fetchRd?.data?.logout_time);
        }else{
            
        }
    });
  }

  useEffect(() => {
    let nowTime = new Date();
    if(logoutTime || logoutTime === 0){
      nowTime.setMinutes(nowTime.getMinutes() + Number(logoutTime));
      localStorage.setItem("logoutTime", setDateTime(nowTime,"yymmddhhttss","hymd"));
    }
  }, [logoutTime]);

  useEffect(() => {
    if(localStorage.getItem("token") && !localStorage.getItem("logoutTime")){
      logoutTimeSetting();
    }else if(localStorage.getItem("logoutTime")){
      interval.current = setInterval(function() {
          let chkTime = new Date();
          let logoutTimeSet = String(localStorage.getItem("logoutTime")).indexOf("-") > 0 || String(localStorage.getItem("logoutTime")).indexOf(".") > 0 ? String(localStorage.getItem("logoutTime")).replace(/-\./g,"/") : localStorage.getItem("logoutTime");
          let logoutTime = new Date(logoutTimeSet);

          if(chkTime >= logoutTime){
            clearInterval(interval.current);
            localStorage.removeItem("logoutTime");

            auth.clearAppStorage();

            localStorage.setItem("logoutAll",true);

            window.location.href = "/login";
            window.location.reload(true);
          }
      }, 1000);
    }

    return () => {
        clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      if(!localStorage.getItem("token") && localStorage.getItem("logoutAll")){
        localStorage.removeItem("logoutAll");
        window.location.href = "/login";
        window.location.reload(true);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <SWRConfig value={swrConfig(failAlertPopup)}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/check" component={Check} />

          {/* 대시보드 */}
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/dashboard/notice" component={NoticeListPage} />
          <Route
            exact
            path="/dashboard/notice/detail/:detailId"
            component={NoticeDetailPage}
          />

          {/* 사내공지 */}
          <Route exact path="/inhousenotice" component={InhouseNotice}>
            <WithAccessAuth
              Component={InhouseNotice}
              level={2}
              redirectUrl="/"
            />
          </Route>

          {/* 사내공지 상세 */}
          <Route
            exact
            path="/inhousenotice/detail/:detailId"
            component={InhouseNoticeDetail}
          />

          {/* as */}
          <Route exact path="/as">
            <WithAccessAuth Component={As} level={2} redirectUrl="/" />
          </Route>
          <Route exact path="/as/register" component={AsRegister} />
          <Route exact path="/as/edit/:detailId" component={AsRegister} />

          {/* 부속품 관리 */}
          <Route exact path="/part" component={Part} />
          <Route exact path="/part/edit/:detailId" component={PartRegister} />
          <Route exact path="/part/register" component={PartRegister} />

          {/* 상담관리 */}
          <Route exact path="/consult">
            <WithAccessAuth
              Component={withUser(Consult)}
              level={3}
              redirectUrl="/"
            />
          </Route>
          <Route
            exact
            path="/consult/edit/:detailId"
            component={ConsultRegister}
          />
          <Route exact path="/consult/register" component={ConsultRegister} />
          <Route exact path="/consult/imsi" component={ConsultImsiList} />

          {/* TDS */}
          <Route exact path="/tds">
            <WithAccessAuth Component={Tds} level={4} redirectUrl="/" />
          </Route>
          <Route exact path="/tds/edit/:detailId" component={TdsRegister} />
          <Route exact path="/tds/register" component={TdsRegister} />

          {/* 위탁관리 */}
          <Route exact path="/consignment">
            <WithAccessAuth
              Component={withUser(Consignment)}
              level={3}
              redirectUrl="/"
            />
          </Route>
          <Route exact path="/consignment/edit/:detailId">
            <WithAccessAuth
              Component={withUser(ConsignmentEdit)}
              level={3}
              redirectUrl="/consignment"
            />
          </Route>

          {/* 지점회계 */}
          <Route exact path="/account">
            <WithAccessAuth
              Component={withUser(Account)}
              level={4}
              redirectUrl="/consignment"
            />
          </Route>

          {/* 일정관리 */}
          <Route exact path="/schedule" component={Schedule} />

          {/* 매입관리 */}
          <Route exact path="/purchase">
            <WithAccessAuth
              Component={withUser(Purchase)}
              level={3}
              redirectUrl="/"
            />
          </Route>

          <Route exact path="/purchase/edit/:detailId">
            <WithAccessAuth
              Component={withUser(PurchaseEdit)}
              level={3}
              redirectUrl={"/purchase"}
            />
          </Route>

          {/* settings */}
          <Route exact path="/settings" component={Settings} />

          {/* ------------- */}
          {/* 주문관리 */}
          <Route exact path="/order" component={Order} />

          {/* 고객관리 */}
          <Route exact path="/user" component={User} />
          <Route exact path="/user/register" component={UserRegister} />
          <Route exact path="/user/edit/:detailId" component={UserRegister} />
        </Switch>
      </BrowserRouter>
      {alertPopup !== null && (
        <AlertPopup
          handleClose={() => (window.location.href = "/dashboard")}
          firstBtnClick={() => (window.location.href = "/dashboard")}
          title={alertPopup.title}
          text={alertPopup.text}
        />
      )}
      <Core />
    </SWRConfig>
  );
};

export default App;
