import moment from "moment";

//date : format 사용할 데이트 형식. ex)Date('2022-05-14','YYYY.MM.DD') -> 2022.05.14 노출
export const Date = (date, format) => {
  const formatData = date !== null ? moment(date).format(format) : " - ";
  if (formatData === "Invalid date") return " - ";
  return formatData;
};

export const NumberChange = (value) => {
  return value.replace(/[^0-9]/gi,'')
}

// 가격에 ,찍기
export const Dot = (price) => {
  if (price === undefined || price === null || price === 0) {
    return 0;
  } else {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

// 가격에 ,찍기 & 음수 (-) 미노출
export const DotNum = (price) => {
  if (price === undefined || price === null || price === 0) {
    return 0;
  } else {
    if (price.toString().includes("-")) {
      return price
        .toString()
        .replace("-", "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};

//개인결제 상태
export const PersonalStatus = (data) => {
  //넘버처리
  const status = Number(data);
  if (status === 0) {
    return "신청";
  } else if (status === 1) {
    return "결제 대기";
  } else if (status === 2) {
    return "진행중";
  } else if (status === 3) {
    return "배송중";
  } else if (status === 4) {
    return "완료";
  } else if (status === 10) {
    return "취소";
  } else if (status === 11) {
    return "입금 대기";
  }
};
